export const API_URL = "https://tjpc.com/api"
//export const STRIPE_KEY = "pk_test_S2oFuxiboieVVJL0ErAnaGSu00n4hCrmyF"
export const STRIPE_KEY = "pk_live_sShFjVYgjYfjF5liaADZS6RU00p0tNoDST"

export const isBrowser = () => typeof window !== "undefined"

export const getCart = () =>
  isBrowser() && window.localStorage.getItem("ACCart")
    ? JSON.parse(window.localStorage.getItem("ACCart"))
    : []

export const setCart = array => {
  if (isBrowser() && array.length > 0) {
    window.localStorage.setItem("ACCart", JSON.stringify(array))
  } else {
    window.localStorage.removeItem("ACCart")
  }
  return true
}

export const getToken = () =>
  isBrowser() && window.localStorage.getItem("ACAdmin")
    ? window.localStorage.getItem("ACAdmin")
    : ""

export const setToken = token => {
  if (isBrowser() && token) {
    window.localStorage.setItem("ACAdmin", token)
  } else {
    window.localStorage.removeItem("ACAdmin")
  }
  return true
}

export const getOrder = () =>
  isBrowser() && window.localStorage.getItem("ACOrder")
    ? JSON.parse(window.localStorage.getItem("ACOrder"))
    : { contact: {}, shipping: {}, billing: {} }

export const setOrder = object => {
  if (isBrowser() && object) {
    window.localStorage.setItem("ACOrder", JSON.stringify(object))
  } else {
    window.localStorage.removeItem("ACOrder")
  }
  return true
}

export function getLocationPrice(location) {
  return location === "Vertical"
    ? 70000
    : location === "Horizontal"
    ? 110000
    : 0
}

export function fmtSeer(num) {
  return `${num.toFixed(2)} SEER`
}

export function fmtTonnage(num) {
  return `${num.toFixed(1)} Ton`
}

export function fmtBtu(num) {
  return `${num.toLocaleString("en-US")} BTU`
}

export function fmtCurrency(int) {
  const amt = int / 100
  return `${amt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}`
}

export const TONNAGES = [
  {
    id: "1.5",
    sf: "600 - 800 SF",
  },
  {
    id: "2",
    sf: "800 - 1,000 SF",
  },
  {
    id: "2.5",
    sf: "1,000 - 1,200 SF",
  },
  {
    id: "3",
    sf: "1,200 - 1,400 SF",
  },
  {
    id: "3.5",
    sf: "1,400 - 1,700 SF",
  },
  {
    id: "4",
    sf: "1,700 - 2,100 SF",
  },
  {
    id: "5",
    sf: "2,100 - 2,500 SF",
  },
]
